<template>
  <div class="feedback-wrapper">
    <div class="title text-white">Report Feedback</div>
    <div class="content">
      <b-form class="p-4" @submit="onSubmit">
        <b-form-group label="Name:"
                      label-cols="2"
                      label-for="name"
                      label-size="sm"
                      horizontal>
          <b-form-input id="name"
                        autocomplete="off"
                        placeholder="Enter Name (optional)"
                        size="sm"
                        v-model="$v.form.name.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Feedback Type:"
                      label-cols="4"
                      label-for="feedbackType"
                      label-size="sm"
                      horizontal>
          <b-form-select id="feedbackType"
                         autocomplete="off"
                         size="sm"
                         v-model="$v.form.feedbackType.$model"
                         :state="!$v.form.feedbackType.$error"
                         :options="feedbackTypes"
                         @input="$v.form.feedbackType.$touch()"
          ></b-form-select>
          <b-form-invalid-feedback v-if="!$v.form.feedbackType.required">
            Please select a feedback type.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Description:"
                      label-for="description"
                      label-size="sm">
          <b-form-textarea id="description"
                           autocomplete="off"
                           placeholder="Tell us more..."
                           rows="4"
                           size="sm"
                           v-model="$v.form.description.$model"
                           :state="!$v.form.description.$error"
                           @input="$v.form.description.$touch()"
          ></b-form-textarea>
          <b-form-invalid-feedback v-if="!$v.form.description.required">
            Please tell us more about the issue/suggestion.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group :state="!$v.images.$error">
          <b-form-file class="file-uploader"
                      accept="image/jpeg, image/png, image/gif"
                      placeholder="Select images.."
                      ref="images-input"
                      v-model="$v.images.$model"
                      multiple
                      :file-name-formatter="formatFileName"
                      :state="!$v.images.$error"
                      @change="processFile"
                      @input="$v.images.$touch()"
          ></b-form-file>
          <b-form-invalid-feedback id="inputImagesFeedback">
            <span v-if="!$v.images.validTotalFileSize">
              Total file size {{ totalFileSize | byteToMB}}MB exceeds the
              max limit {{ maxTotalFileSize | byteToMB }}MB.
            </span>
            <span v-if="!$v.images.validFileSize && images.length > 1">
              Some file exceeds the max limit {{ maxFileSize | byteToMB }}MB.
            </span>
            <span v-if="!$v.images.validFileSize && images.length === 1">
              File {{ totalFileSize | byteToMB}}MB exceeds the
              max limit {{ maxFileSize | byteToMB }}MB.
            </span>
            <span ></span>
          </b-form-invalid-feedback>
        </b-form-group>

        <!-- Upload Image Thunbnails -->
        <div v-if="images.length > 0 && $v.images.validTotalFileSize">
          <div class="image-wrapper"
               v-for="img in imagesData"
               :key="img.id">
            <i class="fas fa-times remove-icon"
               title="Remove Image"
               @click="removeImage(img.id)">
            </i>
            <b-img class="image-thumbnail"
                   :class="img.class"
                   :alt="img.name"
                   :src="img.uri"
                   :title="img.name"
                   rounded thumbnail fluid
            ></b-img>
          </div>
        </div>

        <!-- Form Buttons -->
        <div class="d-flex justify-content-end mt-4">
          <b-button class="btn-h3-small button"
                    type="submit"
                    variant="success"
                    :disabled="$v.$invalid">
            Submit
          </b-button>
          <b-button class="btn-h3-small ml-2 button"
                    type="button"
                    variant="danger"
                    @click="cancelFeedback">
            Cancel
          </b-button>
        </div>
      </b-form>
    </div>

    <!-- Loading -->
    <loading :active.sync="isLoading"
             :is-full-page="true"
             background-color="#000"
             color="#068bcd"
             :opacity="0.5"
             :width="70"
             :height="70">
      <rainbow-loader></rainbow-loader>
    </loading>
  </div>
</template>

<script>
  const Loading = () => import('vue-loading-overlay');
  const RainbowLoader = () => import('@/components/shared/RainbowLoader');
  const base64Encode = data =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  
  import { required } from 'vuelidate/lib/validators';
  import FilterMixin from '@/mixins/FilterMixin';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name   : 'ReportFeedback',
    mixins : [
      FilterMixin,
    ],
    data() {
      return {
        form : {
          name         : null,
          feedbackType : null,
          description  : null,
        },
        feedbackTypes : [ {
          value    : null,
          text     : 'Select a feedback type',
          disabled : true,
        }, {
          value : 'Issue',
          text  : 'Issue',
        }, {
          value : 'Comment/Suggestion',
          text  : 'Comment/Suggestion',
        } ],
        images             : [],
        imagesData         : [],
        isLoading          : false,
        totalFileSize      : 0,
        validTotalFileSize : true,
        maxTotalFileSize   : 26214400, // 25MB (1024 base)
        maxFileSize        : 10485760, // 10MB (1024 base)
        invalidFileCounter : 0,
      }
    },
    components : {
      Loading,
      RainbowLoader,
    },
    watch : {
      images(newValue, oldValue) {
        this.invalidFileCounter = 0;
        if (newValue.length > 0) {
          if (newValue !== oldValue) {
            for (let i = 0; i <= newValue.length; i++) {
              base64Encode(newValue[i])
                .then(value => {
                  this.images[i].id = i;
                  this.imagesData.push({
                    id    : i,
                    uri   : value,
                    name  : 'image-' + i,
                    class : (newValue[i].size <= this.maxFileSize) ?
                      'valid' : 'invalid',
                  });
                })
                .catch(() => {
                  // this.imagesSrc = [];
                  this.imagesData = [];
                });

              if (newValue[i].size > this.maxFileSize)
                this.invalidFileCounter++;
            }
          }
        } else
          this.imagesData = [];
      },
    },
    methods : {
      
      /**
       * Format selected screenshot filename
       * @param files
       */
      formatFileName(files) {
        if (files.length === 1)
          return files.length + ' file selected';
        return files.length + ' files selected';
      },

      /**
       * Submit Form
       */
      async onSubmit(e) {
        e.preventDefault();
        this.isLoading = true;

        const config = {
          headers : { 'Content-Type' : 'multipart/form-data' },
        }

        const formData = new FormData();
        formData.append('name', this.form.name);
        formData.append('feedbackType', this.form.feedbackType);
        formData.append('description', this.form.description);
        
        let i = 0;
        this.imagesData.forEach(async element => {
          const char = await this.dataURItoBlob(element.uri);
          formData.append('file', char, `image-${i+1}.png`);
          i = i + 1;
        });
        
        await this.$http.post('/api/report_feedback', formData, config)
          .then(() => {
            this.isLoading = false;
            this.$emit('close-report-feedback');
            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Success',
              text  : 'Feedback submitted successfully',
            });
          }).catch(() => {
            this.isLoading = false;
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed',
              text  : 'Oops! Something went wrong!',
            });
          });
      },

      /**
       * Remove Image
       * @param imageID
       */
      removeImage(imageID) {
        // update images
        const img = this.images.map(element => element.id).indexOf(imageID);
        this.images.splice(img, 1);

        // update imagesSrc
        const src = this.imagesData.map(element => element.id).indexOf(imageID);
        this.imagesData.splice(src, 1);
      },

      /**
       * Reset Form
       */
      resetForm() {
        this.form.name = null;
        this.form.feedbackType = null;
        this.form.description = null;
        this.clearImages();
        this.$nextTick(() => {
          this.$v.$reset();
        });
      },

      /**
       * Clear Images
       */
      clearImages() {
        this.$refs['images-input'].reset();
        this.images = [];
        this.imagesData = [];
      },

      /**
       * Close Report Feedback component
       */
      cancelFeedback() {
        this.$emit('close-report-feedback');
      },
      
      /**
       * Convert from Data URI to Blob
       * @param dataURI
       */
      dataURItoBlob(dataURI) {
        var byteString = atob(dataURI.split(',')[1]);

        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++)
          ia[i] = byteString.charCodeAt(i);

        const bb = new Blob([ab], { "type" : mimeString });
        return bb;
      },


      /**
       * Process File on change
       * @param event
      */
      processFile(event) {
        this.totalFileSize = 0;
        Object.keys(event.target.files).forEach(async key => {
          this.totalFileSize = this.totalFileSize +
            await event.target.files[key].size;
        });
      },
    },
    validations() {
      return {
        form : {
          name         : {},
          feedbackType : { required },
          description  : { required },
        },
        images : {
          validTotalFileSize() {
            return this.totalFileSize <= this.maxTotalFileSize ? true : false;
          },
          validFileSize() {
            return this.invalidFileCounter > 0 ? false : true;
          },
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/shared/report-feedback";
</style>